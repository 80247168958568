<template>
  <!-- <v-container
    id="mensajeria"
    fluid
    tag="section"
    class="pa-1"
  >
    <v-row justify="center">
      <v-col cols="12" sm="10" md="10">
        <main-header />
      </v-col>
    </v-row>
  </v-container> -->
  <v-dialog
    v-model="show"
    persistent
    max-width="70vw"
    scrollable
    class="z-index-lg"
  >
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-card
            height="480"
            max-height="480"
            class="overflow-hidden"
            style="position:relative !importante"
          >
            <v-overlay
              :value="loadingSend"
              color="white"
              absolute
              opacity=".8"
            >
              <v-progress-circular
                indeterminate
                size="64"
                color="primary"
              />
            </v-overlay>
            <v-card-title class="white--text primary py-2">
              <v-icon color="white" class="mt-n1 mr-1 mdi-rotate-315">mdi-send</v-icon>
              <span class="font-weight-bold text-h5">Enviar SMS</span>
              <v-spacer />
                <v-btn
                  plain
                  small
                  icon
                  class="px-0"
                  @click="close"
                >
                  <v-icon size="18" color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-row class="my-0" no-gutters>
                <v-col cols="5" sm="6" md="5" class="border-right pr-0">
                    <v-row class="ma-0">
                    <v-col cols="12" class="d-flex justify-space-between align-center">
                      <span class="primary--text font-weight-medium py-1">Destinatarios</span>
                      <v-btn
                        v-if="selectCli.length > 0"
                        plain
                        small
                        class="pr-0"
                        @click="deleteAllCli"
                      >
                        todos
                        <v-icon size="18" color="red">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pa-0"><v-divider /></v-col>
                  </v-row>
                  <v-row class="ma-0 fill-height">
                    <v-col cols="12" class="grey lighten-5 px-0 pt-0 content-scroll overflow-y-scroll" style="height:400px">
                      <modal-clients-send
                        v-model="selectCli"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5" sm="6" md="7" class="pa-4">
                  <v-row>
                    <v-col cols="12" class="d-flex justify-end py-0">
                      <v-checkbox
                        v-model="customSms"
                        color="icono"
                        class="pt-0"
                        >
                        <template v-slot:label>
                          <span class="text-h4">Personalizado</span>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" class="d-flex pt-0">
                      <search-client
                        v-model="selectCli"
                        :items="clients"
                        :loading="loadClients"
                        :custom="customSms"
                      />
                    </v-col>
                  </v-row>
                  <validation-observer ref="SMS_FORM" tag="div">
                    <v-row>
                      <v-col cols="12" class="d-flex flex-column" :class="{'mt-4': !customSms}">
                        <span class="blue-grey--text text--lighten-2 py-3">
                          Mensaje:
                        </span>
                        <validation-provider name="Mensaje" vid="message" rules="required" v-slot="{ errors }">
                          <v-textarea
                            v-model="message"
                            rows="3"
                            placeholder="Escribe su Mensaje"
                            counter
                            filled
                            background-color="grey lighten-5"
                            class="expand-search"
                            :rules="validate"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </validation-observer>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-btn
                        color="blue-grey lighten-5"
                        tile
                        depressed
                        class="mx-2 px-8"
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="icono"
                        tile
                        depressed
                        dark
                        class="mx-2 px-8"
                        @click="sendSMS"
                      >
                        <v-icon  class="mt-n1 mr-1 mdi-rotate-315">mdi-send</v-icon>
                        Enviar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </v-dialog>
</template>
<script>
import { getClients, sendMessage } from '@/services/mensajeria'

export default {
  name: 'SendMessage',
  components: {
    SearchClient: () => import(
      /* webpackChunkName: 'search-client' */
      './Components/SearchClient.vue'
    ),
    ModalClientsSend: () => import(/* webpackChunkName: 'modal-clients-send' */ './Components/ModalClientsSend.vue'),
  },
  props: {
    value: Boolean,
  },
  data () {
    return {
      show: this.value,
      customSms: false,
      selectCli: [],
      clients: [],
      loadClients: false,
      loadingSend: false,
      message: '',
      validate: [v => v.length <= 40 || 'Máximo 40 caracteres'],
      allClients: false,
    }
  },
   watch: {
    show (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.show = val
    },
  },
  created () {
    this.getClientsData()
  },
  methods: {
    deleteAllCli () {
      this.selectCli = []
    },

    send () {
      this.allClients = true
    },

    close () {
      this.show = false
      this.selectCli = []
      this.message = ''
      this.$refs.SMS_FORM.reset()
    },

    async getClientsData () {
      this.loadClients = true
      try {
        this.clients = await getClients()
      } catch (error) {
        console.log(error)
      } finally {
        this.loadClients = false
      }
    },

    async sendSMS () {
      const valid = await this.$refs.SMS_FORM.validate()
      if (this.selectCli.length === 0) {
        this.$root.$showAlert(
            'Debe seleccionar al menos un destinatario para continuar.',
            'error',
          )
        return
      }

      if (valid) {
        this.loadingSend = true
        const sendClients = this.selectCli.map((cli) => {
          return {
            co_cli: cli.co_cli,
            nombre: cli.cli_des,
            telefono: cli.telefonos,
            msg: this.message,
          }
        })
        try {
          const response = await sendMessage(
            {
              datos: {
                destinatarios: sendClients,
              },
            }
          )
           this.$emit('sended')
          this.$root.$showAlert('Mensajes Enviado Exitosamente.', 'success')
          this.close()
        } catch (error) {
          this.$root.$showAlert(
            'Lo sentimos, hubo un error al intentar enviar el Mensaje.',
            'error',
          )
          console.log(error)
        } finally {
          this.loadingSend = false
        }
      }
    },
  }
}
</script>
<style>
.border-right {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 thin 0 0;
}

.overflow-hidden {
  overflow: hidden !important;
}
</style>
